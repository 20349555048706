.container-Home {
  height: 100vh;
  overflow: hidden;
  display: flex;
  min-height: 570px;
}
.background-div {
  position: absolute;
  width: 100%;
  min-height: 570px;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 1;
  height: 100%;
}

.background-img {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
}

.mid-home-div-text {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 20%;
  font-family: "Kollectif";
  color: white;
  text-align: left;
  max-width: 40%;
  margin-top: auto;
  margin-bottom: auto;
}
.mid-home-div-text h1 {
  font-size: 120px;
  margin-bottom: 0;
}
.mid-home-div-text h2 {
  font-weight: 10;
  text-shadow: 2px 2px 10px;
}

.mid-home-div-logo {
  z-index: 10;
  position: relative;
  width: 30%;

  /* top: 20%; */
  left: 5%;

  display: flex;
}
.mid-home-div-logo img {
  width: 100%;
  opacity: 0.25;
  margin: auto;
  max-width: 500px;
}

.home-button {
  position: absolute;
  right: 15%;
  top: 80%;
  margin-top: 0;
  z-index: 10;
  width: 200px;
  left: unset;
  /* margin: auto;
  margin-top: 80px;
  position: relative;
  left: 20%;
  width: 100%; */
}

.btn-get-started {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #dc3c4d;
  cursor: pointer;
  text-decoration: none;
}

.btn-get-started:hover {
  background: rgb(224, 135, 0);
  box-shadow: 1px 1px 10px black;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
  .mid-home-div-logo {
    position: absolute;
    top: 15%;
  }
  .mid-home-div-text {
    /* left: 40%; */
  }

  .mid-home-div-text h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 650px) {
  .mid-home-div-logo {
    display: none;
  }
  .mid-home-div-text {
    top: 20%;
    left: 15%;
    width: 70%;
    max-width: none;
  }

  .mid-home-div-text h1 {
    font-size: 60px;
  }

  .mid-home-div-text h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: 100vh;
    width: inherit;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  .home-button {
    position: absolute;
    right: 10%;
    top: 80%;
    margin-top: 0;
    z-index: 10;
    width: 200px;
    left: unset;
  }
  .container-Home {
    height: 100vh;
    width: inherit;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .mid-home-div-logo {
    display: none;
  }
  .mid-home-div-text {
    top: 20%;
    left: 15%;
    width: 70%;
    max-width: none;
  }

  .mid-home-div-text h1 {
    font-size: 60px;
  }

  .mid-home-div-text h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: unset;
    width: 100vw;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  .home-button {
    position: absolute;
    right: 10%;
    top: 80%;
    margin-top: 0;
    z-index: 10;
    width: 200px;
    left: unset;
  }
  .container-Home {
    height: 100vh;
    width: inherit;
    min-height: unset;
  }
}
