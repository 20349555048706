.services-container {
  background: rgba(230, 230, 230);
  min-height: 100vh;
  width: 90vw;
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 5vw;
  padding-left: 5vw;
  padding-top: 200px;
  text-align: center;
  font-family: "Kollectif";
}
.services-container .filigrane {
  opacity: 0.4;
  font-size: 1500%;
  z-index: -10;
  color: white;
  /* top: 0; */
  position: absolute;
  text-align: center;
  top: -200px;
  left: 35%;
  z-index: -10;
}

.services-div {
  width: 25%;
  min-width: 270px;
  /* height: 400px; */
  background: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  border: 1px solid rgba(24, 30, 56, 1);
  box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2);
  padding: 40px;
  margin: 2%;
  opacity: 1;
  text-align: left;
  color: grey;
  font-size: 18px;
  font-weight: lighter;
  text-align: justify;
}
.services-div h3 {
  font-size: 28px;
  color: #dc3c4d;
  font-weight: normal;
  margin: 5px;
}

.services-div img {
  width: 40%;
}

@media only screen and (max-width: 650px) {
  .services-container {
    padding-top: 100px;
  }

  .services-container .filigrane {
    font-size: 80px;
    top: -80px;
    left: 20px;
    /* opacity: 0.15; */
  }
  .services-div {
    height: auto;
    padding: 20px;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .services-container {
    padding-top: 100px;
  }

  .services-container .filigrane {
    font-size: 130px;
    top: -150px;
    left: 80px;
    /* opacity: 0.15; */
  }
  .services-div {
    height: auto;
    padding: 20px;
  }
}
