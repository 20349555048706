.contact-container {
  position: relative;
  min-height: 50vh;
  width: 100%;
  display: flex;
  /* border-top: 2px solid rgb(218, 133, 23); */
  background: linear-gradient(rgb(25, 65, 90), rgba(24, 30, 56, 1));
  /* border-top-left-radius: 10%; */
  flex-wrap: wrap;
  font-family: "Kollectif";
  z-index: 0;
  box-shadow: 5px 5px 10px grey;
  flex-direction: column;
  /* margin-top: 100px; */
}

.contact-container h2 {
  font-size: 54px;
  color: #dc3c4d;
  font-weight: normal;
  position: relative;
  left: -25%;
}
.contact-container .contactForm p {
  display: inline;
  color: rgb(94, 171, 235);
  font-size: 18px;
  text-align: justify;
  margin: 5px;
}

.contact-container a {
  color: rgb(94, 171, 235);
}

.contactForm {
  width: 50%;
  height: max-content;
  padding: 10px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  /* margin-top: 50px; */
  flex-direction: column;
}

.contactForm form {
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.contactForm input {
  width: 250px;
  border-radius: 4px;
}

.contactForm textarea {
  border-radius: 4px;
  min-height: 200px;
  min-width: 500px;
  max-height: 600px;
  max-width: 800px;
}

#contactMessage {
  margin: auto;
  margin-top: 30px;
}

#contactMessage input {
  width: 500px;
  height: 200px;
  overflow-wrap: normal;
}

#sendButton {
  margin-right: auto;
  margin-left: auto;
}

#sendButton button {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #dc3c4d;
  cursor: pointer;
  text-decoration: none;
  width: min-content;
  font-family: "Kollectif";
}

#sendButton button:hover {
  background: rgb(224, 135, 0);
  box-shadow: 1px 1px 10px black;
}

.lien {
  transition: 0.5s;
}

.lien:hover {
  color: rgb(224, 135, 0);
}

.filigrane {
  opacity: 0.2;
  font-size: 1500%;
  z-index: -10;
  color: rgb(24, 30, 56);
  position: absolute;
  text-align: center;
  top: -22%;
  left: 25%;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 650px) {
  .contactForm textarea {
    min-width: 100px;
    width: 100%;
  }
  .contactForm {
    width: 80%;
  }

  .contact-container h2 {
    margin-left: 20%;
  }

  .contact-container .filigrane {
    font-size: 80px;
    top: 0px;
    left: 17%;
  }
}
