.container-Home {
  height: 100vh;
  overflow: unset;
  display: flex;
  min-height: 570px;
}
.submitForm {
  background: aliceblue;
  opacity: 0.8;
  border-radius: 5px;
  padding: 20px;
  transition: 0.5s;
  /* box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2); */
  color: black;
  box-shadow: 2px 2px 10px black;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: scroll;
}

.submitForm label {
  margin: 10px;
}
#submitFormDateInput {
  cursor: pointer;
}

.formAlert {
  color: red;
}
.background-div {
  position: absolute;
  width: 100%;
  min-height: 570px;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 1;
  height: 100%;
}

.background-img {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
}

.blog-home-title {
  z-index: 100;
  /* position: absolute;
    top: 20%; */
  font-family: "Kollectif";
  color: white;
  /* text-align: left; */
  /* max-width: 60%; */
  margin: 80px auto;
  width: 60%;
}
.blog-home-title-div {
  border-bottom: 1px solid black;
}
.blog-home-title p {
  font-size: 20px;
  font-weight: 2;
  text-align: center;
  text-shadow: 2px 2px 10px black;
}
.blog-home-title h1 {
  font-size: 80px;
  margin: 0;
  /* text-decoration: underline; */
  text-align: center;
  font-weight: 1;
  text-shadow: 2px 2px 10px black;
}
.blog-home-title h2 {
  font-weight: 10;
  text-shadow: 2px 2px 10px black;
  text-align: center;
}

.mid-home-div-logo {
  z-index: 10;
  position: relative;
  width: 30%;

  /* top: 20%; */
  left: 5%;

  display: flex;
}
.mid-home-div-logo img {
  width: 100%;
  opacity: 0.25;
  margin: auto;
  max-width: 500px;
}

.home-button {
  position: absolute;
  right: 15%;
  top: 80%;
  margin-top: 0;
  z-index: 101;
  width: 200px;
  left: unset;
  /* margin: auto;
      margin-top: 80px;
      position: relative;
      left: 20%;
      width: 100%; */
}

.btn-browse {
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
}

.btn-browse:hover {
  /* background: rgb(163, 59, 59); */
  color: black;
  box-shadow: 1px 1px 10px white;
}

.dropZone {
  border: 1px dashed blue;
  margin: 40px;
  transition: 0.5s;
  border-radius: 5px;
  padding: 10px;
}

.dropZone img {
  height: 50px;
  z-index: -1000;
  position: relative;
}
.dropZone p {
  margin: 0;
  text-shadow: none;
}
#getFile {
  display: none;
}

.btn-get-started {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  z-index: -1000;
}

.btn-get-started:hover {
  color: black;
  /* box-shadow: 1px 1px 10px rgb(7, 7, 7); */
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

#spinner-modal {
  /* display: none; */
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2) url("../assets/spinner.gif") 50% 50%
    no-repeat;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
  .mid-home-div-logo {
    position: absolute;
    top: 15%;
  }
  .blog-home-title {
    left: 40%;
  }

  .blog-home-title h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 650px) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    /* left: 15%; */
    width: 70%;
    max-width: none;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: 100vh;
    width: inherit;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  .home-button {
    position: absolute;
    right: 10%;
    top: 80%;
    margin-top: 0;
    z-index: 10;
    width: 200px;
    left: unset;
  }
  .container-Home {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    left: 15%;
    width: 70%;
    max-width: none;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: unset;
    width: 100vw;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  .home-button {
    position: absolute;
    right: 10%;
    top: 80%;
    margin-top: 0;
    z-index: 10;
    width: 200px;
    left: unset;
  }
  .container-Home {
    height: 100vh;
    width: inherit;
    min-height: unset;
  }

  .submitForm {
    background: aliceblue;
    opacity: 0.8;
    border-radius: 5px;
    padding: 20px;
    transition: 0.5s;
    box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2);
  }
}
