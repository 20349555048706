.work-container {
  background: white;
  min-height: 100vh;
  width: 90vw;
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 5vw;
  padding-left: 5vw;
  margin-top: 50px;
  text-align: center;
  font-family: "Kollectif";
}
.work-container .filigrane {
  opacity: 0.1;
  font-size: 1500%;
  z-index: 10;
  color: rgb(24, 30, 56);
  /* top: 0; */
  position: absolute;
  text-align: center;
  top: -300px;
  left: 25%;
}

.work-block-impair,
.work-block-pair {
  width: 100%;
  min-width: 400px;
  height: 400px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  /* border: 1px solid rgba(24, 30, 56, 1); */
  /* box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2); */
  padding: 2%;
  margin: 2%;
  margin-top: 50px;
  opacity: 1;
  text-align: left;
  color: grey;
  font-size: 18px;
  font-weight: lighter;
  text-align: justify;
  display: flex;
  justify-content: space-around;
}

.text-block {
  width: 30%;
  /* border: 1px solid rgba(24, 30, 56, 1); */
  height: 88%;
  color: grey;
  font-weight: lighter;
  text-align: justify;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* vertical-align: middle; */
  padding: 2%;
  /* z-index: -15; */
}
.text-block h3 {
  font-size: 28px;
  color: #dc3c4d;
  font-weight: normal;
  margin: 5px;
  text-align: center;

  /* text-align: left; */
}
.img-block {
  width: 60%;
  /* border: 1px solid rgba(24, 30, 56, 1); */
  height: 100%;
  /* overflow: hidden; */
  display: flex;
}

.img-block img {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.5s;
  box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2);
}

.img-block img:hover {
  transform: scale(1.05);
}
.work-container a {
  display: flex;
  justify-content: center;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 650px) {
  .work-container {
    margin-top: 0;
    padding-top: 50px;
  }
  .work-block-impair {
    flex-direction: column-reverse;
    /* border-bottom: 1px solid grey; */
    margin-top: 60px;
    min-width: unset;
  }
  .work-block-pair {
    flex-direction: column;
    /* border-bottom: 1px solid grey; */
    margin-top: 60px;
    min-width: unset;
  }
  .text-block {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .img-block {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .work-container .filigrane {
    font-size: 100px;
    top: -90px;
    right: 10%;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .work-container .filigrane {
    font-size: 130px;
    top: -160px;
    right: 15%;
  }

  .work-block-impair,
  .work-block-pair {
    height: unset;
  }
}
