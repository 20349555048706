.shape-white-div {
  width: 100%;
  z-index: -12;
  height: 100px;
}
.shape-white-div img {
  width: 100%;
  z-index: -12;
  /* position: absolute;
  top: -5px; */
  height: 100px;
}
