.about-container {
  margin-top: 30px;
  height: calc(90vh);
  width: 100vw;
  /* overflow: hidden; */
  display: flex;
  position: relative;
  min-height: 645px;
}

.filigrane {
  opacity: 0.1;
  font-size: 1500%;
  z-index: -10;
  color: rgb(24, 30, 56);
  /* top: 0; */
  position: absolute;
  text-align: center;
  top: -35%;
  left: 25%;
}
.left-block {
  width: 30%;
}
.right-block {
  width: 50%;
  z-index: -13;
}
.right-block img {
  width: 80%;
  margin: 5%;
}

.left-block {
  margin-top: 50px;
  margin-left: 10%;
  font-family: "Kollectif";
  color: #ff5757;
  text-align: left;
  padding-right: 10%;
  font-size: 18px;
  font-weight: lighter;
  text-align: justify;
}
.left-block h2 {
  font-size: 54px;
  color: #dc3c4d;
  font-weight: normal;
}
.left-block h3 {
  font-size: 20px;
  /* font-weight: lighter; */
  text-align: right;
}

.about-circle-img {
  z-index: -1;
  position: relative;
}

.about-circle-div {
  position: absolute;
  bottom: -100px;
  left: -100px;
  width: 450px;
  z-index: 1;
}

.about-circle-div p {
  position: absolute;
  z-index: 10;
  max-width: 50%;
  left: 25%;
  margin-left: auto;
  margin-right: auto;
  top: 37%;
  font-size: 20px;
  color: rgb(25, 65, 90);
  font-family: "Kollectif";
}
.about-button {
  margin: auto;
  margin-top: 80px;
  position: relative;
  left: 50%;
  width: auto;
  /* display: inline-block; */
}

.btn-get-about {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: 0.5s;
  color: black;
  /* background: #dc3c4d; */
  cursor: pointer;
  text-decoration: none;
}
.btn-get-about p {
  display: inline-block;
  vertical-align: middle;
  text-decoration: underline;
}
.btn-get-about .arrow-yellow {
  background: rgb(255, 217, 0);
  width: fit-content;
  border-radius: 50px;
  padding: 5px;
  transition: 0.5s;
  font-weight: bolder;
  color: white;
  text-decoration: none;
}

.btn-get-about:hover p {
  color: rgb(25, 65, 90);
}
.btn-get-about:hover .arrow-yellow {
  background: grey;
  color: white;
}

.shape-grey-div {
  width: 100%;
  z-index: -12;
  bottom: -5px;
  position: absolute;
  height: 100px;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
  .about-button {
    margin-top: 40px;
    left: 30%;
  }

  .about-circle-div {
    width: 200px;
    position: absolute;
    bottom: 40px;
    right: 70px;
    top: 60%;
    left: unset;
  }

  .about-circle-div p {
    position: absolute;
    z-index: 10;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    color: rgb(25, 65, 90);
    font-family: "Kollectif";
    line-height: 30px;
    top: 20%;
    left: unset;
    right: -20%;
  }

  .about-circle-div img {
    z-index: -1;
    position: absolute;
    width: 130%;
    top: 0%;
    right: -55%;
  }

  .about-container .filigrane {
    opacity: 0.2;
    font-size: 150px;
    z-index: -10;
    color: rgb(24, 30, 56);
    position: absolute;
    text-align: center;
    top: -30%;
    left: 22%;
  }
}

@media only screen and (max-width: 650px) {
  .about-container .filigrane {
    font-size: 100px;
    top: 0;
    opacity: 0.15;
  }

  .about-container {
    flex-direction: column;
  }

  .left-block {
    width: 80%;
    margin-top: 0;
  }
  .right-block {
    display: none;
  }

  .about-button {
    margin-top: 40px;
    left: 30%;
  }

  .about-circle-div {
    width: 60%;
    position: relative;
    top: 40px;
    left: 0;
  }

  .about-circle-div p {
    position: relative;
    z-index: 10;
    max-width: 60%;
    left: -10%;
    margin-left: auto;
    margin-right: auto;
    top: 5%;
    font-size: 18px;
    color: rgb(25, 65, 90);
    font-family: "Kollectif";
    line-height: 30px;
  }

  .about-circle-div img {
    z-index: -1;
    position: relative;
    top: -45%;
    left: -30%;
    width: 130%;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .about-container .filigrane {
    font-size: 100px;
    top: 0;
    opacity: 0.15;
  }

  .about-container {
    flex-direction: column;
  }

  .left-block {
    width: 80%;
    margin-top: 0;
  }
  .right-block {
    display: none;
  }

  .about-button {
    margin-top: 40px;
    left: 30%;
  }

  /* .about-circle-div {
    width: 60%;
    position: relative;
    top: 40px;
    left: 0;
  }

  .about-circle-div p {
    position: relative;
    z-index: 10;
    max-width: 60%;
    left: -10%;
    margin-left: auto;
    margin-right: auto;
    top: 5%;
    font-size: 18px;
    color: rgb(25, 65, 90);
    font-family: "Kollectif";
    line-height: 30px;
  }

  .about-circle-div img {
    z-index: -1;
    position: relative;
    top: -45%;
    left: -30%;
    width: 130%;
  } */
}
